import React from "react";
import loadable from '@loadable/component'

const SmallTestimonialListing = loadable(() =>  import("./small-testimonial-listing"));
const ImageWithTextContent = loadable(() =>  import("./image-with-text-content"));

const Start = ({ module = { display: '' } }) => {
    const { testimonials } = module;
    const { display } = testimonials;
    
    switch(display) {
        case "small-testimonial-listing":
            return <SmallTestimonialListing module={testimonials} />
        case "image-with-text-content":
            return <ImageWithTextContent module={testimonials} />;
        default:
            return null;
    }
};

export default Start;